import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('../layout/index.vue'),
	redirect: '/home',
	children: [
		{
			path: 'home',
			component: () => import('@/views/home.vue'),
		},
		{
			path: 'company',
			component: () => import('@/views/company.vue'),
		},
		{
			path: 'new',
			component: () => import('@/views/new.vue'),
		},
		{
			path: 'down',
			component: () => import('@/views/down.vue'),
		},
		{
			path: 'us',
			component: () => import('@/views/us.vue'),
		}
	]
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash'
})

export default router
